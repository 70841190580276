import React, { Component } from 'react';
import Plx from 'react-plx';
import "./about.scss";
const parallaxMe = [
  {
    start: "0vh",
    end: "50%",
    properties: [
      {
        startValue: 0,
        endValue: 10,
        unit: "px",
        property: 'blur',
      },
    ],
  },
  {
    start: "0vh",
    end: "50%",
    properties: [
      {
        startValue: 1,
        endValue: .7,
        property: 'opacity',
      },
      // {
      //   startValue: 0,
      //   endValue: -10,
      //   unit: "vh",
      //   property: 'marginTop',
      // },
    ],
  },
];

const parallaxAbout = [
  {
    start: "15%",
    end: "50%",
    properties: [
      {
        startValue: 0,
        endValue: 10,
        unit: "px",
        property: 'blur',
      },
    ],
  },
  {
    start: "0vh",
    end: "50%",
    properties: [
      {
        startValue: 1,
        endValue: .7,
        property: 'opacity',
      },
      // {
      //   startValue: 0,
      //   endValue: -10,
      //   unit: "vh",
      //   property: 'marginTop',
      // },
    ],
  },
];

const parallaxBgEffect = [
  {
    start: "0vh",
    end: "10vh",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  },
];

export default class About extends Component {

  render() {
    return (
      <>
        <div className="flex">
          <h1 class="mobile"><span>Hi, I'm Caleb.</span></h1>
          <Plx className='me' parallaxData={ parallaxMe }><img className="me" src="me.jpg" alt="Caleb Plain" title="Caleb Plain" /></Plx>
          <div className="shim-9" />
          <Plx parallaxData={parallaxAbout} className="right-column">
            <h1 class="tablet"><span>Hi, I'm Caleb.</span></h1>
            <br/>
            <div>
              <p>I am a product designer that loves to create experiences that improve people’s lives. I am constantly building new things and improving old things.</p>
              <p>For the past decade or so, I've been focused on web and mobile products. Check out some of my work below.</p>
            </div>
            <div className="socials">
              <a href="https://twitter.com/_CalebPlain" className="twitter" title="Twitter" target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23 3C22.0424 3.67548 20.9821 4.19211 19.86 4.53C19.2577 3.83751 18.4573 3.34669 17.567 3.12393C16.6767 2.90116 15.7395 2.9572 14.8821 3.28445C14.0247 3.61171 13.2884 4.1944 12.773 4.95372C12.2575 5.71303 11.9877 6.61234 12 7.53V8.53C10.2426 8.57557 8.50127 8.18581 6.93101 7.39545C5.36074 6.60508 4.01032 5.43864 3 4C3 4 -1 13 8 17C5.94053 18.398 3.48716 19.0989 1 19C10 24 21 19 21 7.5C20.9991 7.22145 20.9723 6.94359 20.92 6.67C21.9406 5.66349 22.6608 4.39271 23 3V3Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/calebplain/" className="linkedin" title="LinkedIn" target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 9H2V21H6V9Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <a href="https://www.instagram.com/scenery.inc/" className="instagram" title="Instagram" target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 11.37C16.1234 12.2022 15.9813 13.0522 15.5938 13.799C15.2063 14.5458 14.5932 15.1514 13.8416 15.5297C13.0901 15.9079 12.2385 16.0396 11.4078 15.9059C10.5771 15.7723 9.80977 15.3801 9.21485 14.7852C8.61993 14.1902 8.22774 13.4229 8.09408 12.5922C7.96042 11.7615 8.09208 10.9099 8.47034 10.1584C8.8486 9.40685 9.4542 8.79374 10.201 8.40624C10.9478 8.01874 11.7978 7.87658 12.63 8C13.4789 8.12588 14.2649 8.52146 14.8717 9.1283C15.4785 9.73515 15.8741 10.5211 16 11.37Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5 6.5H17.51" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <a href="https://github.com/plain" className="github" title="GitHub" target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 22V18.13C16.0375 17.6532 15.9731 17.1738 15.811 16.7238C15.6489 16.2738 15.3929 15.8634 15.06 15.52C18.2 15.17 21.5 13.98 21.5 8.52C21.4997 7.12383 20.9627 5.7812 20 4.77C20.4559 3.54851 20.4236 2.19835 19.91 0.999999C19.91 0.999999 18.73 0.649999 16 2.48C13.708 1.85882 11.292 1.85882 9 2.48C6.27 0.649999 5.09 0.999999 5.09 0.999999C4.57638 2.19835 4.54414 3.54851 5 4.77C4.03013 5.7887 3.49252 7.14346 3.5 8.55C3.5 13.97 6.8 15.16 9.94 15.55C9.611 15.89 9.35726 16.2954 9.19531 16.7399C9.03335 17.1844 8.96681 17.6581 9 18.13V22M9 19C4 20.5 4 16.5 2 16L9 19Z" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <a href="https://drive.google.com/file/d/1FBgDh_Uqpvhv9lBE08ztgojzU8KqEa5H/view?usp=sharing" className="resume" target="_blank">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.41667 1.66667H3.75C3.44058 1.66667 3.14384 1.78958 2.92504 2.00837C2.70625 2.22717 2.58334 2.52391 2.58334 2.83333V12.1667C2.58334 12.4761 2.70625 12.7728 2.92504 12.9916C3.14384 13.2104 3.44058 13.3333 3.75 13.3333H10.75C11.0594 13.3333 11.3562 13.2104 11.575 12.9916C11.7938 12.7728 11.9167 12.4761 11.9167 12.1667V5.16667L8.41667 1.66667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.41666 1.66667V5.16667H11.9167" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.58333 8.08333H4.91666" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.58333 10.4167H4.91666" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.08333 5.75H5.5H4.91666" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Resume
              </a>
            </div>
          </Plx>
        </div>
        <hr/>
      </>
    );
  }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DefaultPlayer as Video } from 'react-html5video';
import BackButton from "../components/case-studies/back-button/back-button.js";
import "../components/case-studies/case-studies.scss";
import "../components/project-browser/project.scss";

export default class POS extends Component {
  state = {
    "video-1-controls": false,
  }
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("pos");
  }
  render () {
    if(this.props.privateContent) return (
      <div className="project surface">
        <div className="CaseStudy section">
          <BackButton />
          <h1>{this.props.privateContent && this.props.privateContent.projects["pos"].name}</h1>
          {this.props.privateContent && this.props.privateContent.projects["pos"].description && this.props.privateContent.projects["pos"].description.map((id) =>
            <h2>
              {id}
            </h2>
          )}
          <div className="phones">
            <div className="device-wrapper ipad">
              <div className="ipad-pro">
                {this.props.privateContent &&
                   <Video className={!this.state['video-1-controls'] && "hide-track"} onPause={()=>this.setState({"video-1-controls": false})} onPlay={()=>this.setState({"video-1-controls": true})} muted playsInline="playsinline" autoPlay poster={"/projects/"+this.props.privateContent.projects["pos"].secretID+"/"+this.props.privateContent.projects["pos"].secretID+".jpg"}
                    controls={['PlayPause', 'Seek']}>
                    <source src={"/projects/"+this.props.privateContent.projects["pos"].secretID+"/"+this.props.privateContent.projects["pos"].secretID+".mp4"} type="video/mp4"/>
                  </Video>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return null;
  }
}
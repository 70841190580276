import React, { Component } from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import BackButton from "../components/case-studies/back-button/back-button.js";
import "../components/case-studies/case-studies.scss";
import "../components/project-browser/project.scss";

export default class Checkout extends Component {
  state = {
    "video-1-controls": false,
    "video-2-controls": false,
    "video-3-controls": false,
  }
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("checkout");
  }
  render () {
    if(this.props.privateContent) return (
      <div className="project surface">
        <div className="CaseStudy section">
          <BackButton />
          <h1>{this.props.privateContent && this.props.privateContent.projects["checkout"].name}</h1>
          {this.props.privateContent && this.props.privateContent.projects["checkout"].description && this.props.privateContent.projects["checkout"].description.map((id) =>
            <h2>
              {id}
            </h2>
          )}
          <div className="phones">
            <div className="device-wrapper iphone">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.1667 5.83333L13.3334 10L19.1667 14.1667V5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.6667 4.16667H2.50004C1.57957 4.16667 0.833374 4.91286 0.833374 5.83333V14.1667C0.833374 15.0871 1.57957 15.8333 2.50004 15.8333H11.6667C12.5872 15.8333 13.3334 15.0871 13.3334 14.1667V5.83333C13.3334 4.91286 12.5872 4.16667 11.6667 4.16667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {this.props.privateContent.projects["checkout"].videoTitles[0]}
              </span>
              <div className="iphone-12">
                {this.props.privateContent &&
                  <Video className={!this.state['video-1-controls'] && "hide-track"} onLoadStart={(v)=>{v.target.play();v.target.pause()}} onPause={()=>this.setState({"video-1-controls": false})} onPlay={()=>this.setState({"video-1-controls": true})} muted playsInline="playsinline" poster={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/1-"+this.props.privateContent.projects["checkout"].secretID+".jpg"}
                    controls={['PlayPause', 'Seek']}>
                    <source src={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/1-"+this.props.privateContent.projects["checkout"].secretID+".mp4"} type="video/mp4"/>
                  </Video>
                }
              </div>
            </div>
            <div className="shim" />
            <div className="device-wrapper iphone">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.1667 5.83333L13.3334 10L19.1667 14.1667V5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.6667 4.16667H2.50004C1.57957 4.16667 0.833374 4.91286 0.833374 5.83333V14.1667C0.833374 15.0871 1.57957 15.8333 2.50004 15.8333H11.6667C12.5872 15.8333 13.3334 15.0871 13.3334 14.1667V5.83333C13.3334 4.91286 12.5872 4.16667 11.6667 4.16667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {this.props.privateContent.projects["checkout"].videoTitles[1]}
              </span>
              <div className="iphone-12">
                {this.props.privateContent &&
                  <Video className={!this.state['video-2-controls'] && "hide-track"} onLoadStart={(v)=>{v.target.play();v.target.pause()}} onPause={()=>this.setState({"video-2-controls": false})} onPlay={()=>this.setState({"video-2-controls": true})} muted playsInline="playsinline" poster={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/2-"+this.props.privateContent.projects["checkout"].secretID+".jpg"}
                    controls={['PlayPause', 'Seek']}>
                    <source src={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/2-"+this.props.privateContent.projects["checkout"].secretID+".mp4"} type="video/mp4"/>
                  </Video>
                }
              </div>
            </div>
            <div className="shim" />
            <div className="device-wrapper iphone">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.1667 5.83333L13.3334 10L19.1667 14.1667V5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.6667 4.16667H2.50004C1.57957 4.16667 0.833374 4.91286 0.833374 5.83333V14.1667C0.833374 15.0871 1.57957 15.8333 2.50004 15.8333H11.6667C12.5872 15.8333 13.3334 15.0871 13.3334 14.1667V5.83333C13.3334 4.91286 12.5872 4.16667 11.6667 4.16667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {this.props.privateContent.projects["checkout"].videoTitles[2]}
              </span>
              <div className="iphone-12">
                {this.props.privateContent &&
                  <Video className={!this.state['video-3-controls'] && "hide-track"} onLoadStart={(v)=>{v.target.play();v.target.pause()}} onPause={()=>this.setState({"video-3-controls": false})} onPlay={()=>this.setState({"video-3-controls": true})} muted playsInline="playsinline" poster={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/3-"+this.props.privateContent.projects["checkout"].secretID+".jpg"}
                    controls={['PlayPause', 'Seek']}>
                    <source src={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/3-"+this.props.privateContent.projects["checkout"].secretID+".mp4"} type="video/mp4"/>
                  </Video>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
    return null;
  }
}